import * as React from 'react'
import MapMenu from 'src/layouts/MapMenu'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Badge from '@material-ui/core/Badge'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import TextField from '@material-ui/core/TextField'
import useModelList from 'src/components/useModelList'
import LinkBehavior from 'src/components/LinkBehavior'

import Checkbox from 'genjo-ui/core/Checkbox'

import {
  AccountModel,
  ResourceModel,
} from 'src/data-model'
import MapComponent, { MapContext, Marker, Clusterer } from 'src/components/MapComponent'

import Shell from 'src/components/Shell'
import Navbar from 'src/components/Navbar'

const SAME_COORDINATE_OFFSET = 0.000025


function createInfoWindowContent(account) {
  return `
    <div style="line-height: 1;">
      <h2>${account.BBID}</h2>
      <h3>${account.name}</h3>
      <p>${[account.city, account.state, account.country].filter(x => x).join(', ')}</p>
      <a href="/accounts/${account.id}">View</a>
    </div>
  `
}

export function AdminMap() {

  const { results: accounts = [] } = useModelList({
    model: AccountModel,
    filter: query => query
      .orderBy('BBID', 'desc')
      .where('stripeSubscriptionStatus', 'in', ['active', 'trialing'])
      .where('accountType', '==', 'LIVE'),
    limit: 1000,
  })

  const { results: resources = [] } = useModelList({
    model: ResourceModel,
    limit: 1000,
    filter: query => query
      .orderBy('name', 'asc'),
  })

  const [points, setPoints] = React.useState({
    squares: {},
    indexes: {},
  })

  React.useEffect(
    () => {
      const newPoints = {
        squares: {},
        indexes: {},
      }

      for (let account of accounts) {
        if (!account.geopoint) {
          continue
        }

        const coords = `${account.geopoint?.latitude}:${account.geopoint?.longitude}`
        const currentIndex = newPoints.squares[coords] ?? 0
        newPoints.squares[coords] = currentIndex + 1
        newPoints.indexes[account.id] = currentIndex
      }

      setPoints(newPoints)
    },
    [accounts]
  )

  const [hiddenResources, setHiddenResources] = React.useState(new Set())
  const [filterType, setFilterType] = React.useState('ALL')

  const filteredAccounts = React.useMemo(
    () => accounts.filter(account => {

      if (filterType !== 'ALL') {
        if (filterType === 'OLD' && !account.isOldSchool) return false
        if (filterType === 'NEW' && account.isOldSchool) return false
      }

      if (hiddenResources.has(account.resourceId)) {
        return false
      }

      return true
    }),
    [accounts, hiddenResources, filterType]
  )

  const accountsWithoutAddress = React.useMemo(
    () => accounts.filter(account => !account.geopoint),
    [accounts]
  )

  const navbar = React.useMemo(
    () => (

      <MapMenu>
        <ListItem>
          <ListItemText
            primary={`Showing ${filteredAccounts.length} of ${accounts.length}`}
          />
        </ListItem>
        <ListItem>
          <TextField
            value={filterType}
            onChange={event => setFilterType(event.target.value)}
            select
          >
            <MenuItem value="ALL">All Account Types</MenuItem>
            <MenuItem value="OLD">Old School</MenuItem>
            <MenuItem value="NEW">Cloud</MenuItem>
          </TextField>
        </ListItem>

        {resources.map(resource => (
          <ListItem
            key={resource.id}
            button
            onClick={() => hiddenResources.has(resource.id)
              ? setHiddenResources(new Set([...hiddenResources].filter(id => id !== resource.id)))
              : setHiddenResources(new Set([...hiddenResources].concat(resource.id)))
            }
          >
            <ListItemIcon>
              <Checkbox checked={!hiddenResources.has(resource.id)} />
            </ListItemIcon>

            <ListItemText primary={resource.name} />
          </ListItem>
        ))}

        {accountsWithoutAddress.length > 0 && (
          <ListSubheader>
            Missing Addresses
            <Badge
              badgeContent={accountsWithoutAddress.length}
              color="error"
            >
              <span style={{ width: 20 }} />
            </Badge>
            </ListSubheader>
        )}

        {accountsWithoutAddress.map(account => (
          <ListItem
            key={account.id}
            component={LinkBehavior}
            to={`/accounts/${account.id}`}
            button
          >
            <ListItemText primary={account.name} secondary={account.BBID} />
          </ListItem>
        ))}
      </MapMenu>
    ),
    [resources, hiddenResources, accountsWithoutAddress, filteredAccounts, accounts, filterType],
  )

  return (
    <Shell
      navbar={<Navbar links={navbar} />}
    >
      <Box
        sx={{ aspectRatio: '16 / 7', position: 'relative' }}
      >
        <MapComponent
          zoom={3}
          disableDoubleClickZoom
          aspectRatio={16/7}
          initialCenter={{ lat: 40.399, lng: -102.344 }}
        >
            <MapContext.Consumer>
              {({ google }) => Boolean(google) && (
                <Clusterer>
                  {accounts
                    .filter(account => {
                      const coords = `${account.geopoint?.latitude}:${account.geopoint?.longitude}`
                      return points.indexes[account.id] !== undefined
                        &&  points.squares[coords] !== undefined
                    })
                    .map(account => {
                      const index = points.indexes[account.id]
                      const coords = `${account.geopoint?.latitude}:${account.geopoint?.longitude}`
                      const size = points.squares[coords]

                      const squareSize = Math.ceil(Math.sqrt(size))
                      const latOffset = Math.floor(index / squareSize)
                      const lngOffset = index % squareSize

                      const lat = account.geopoint?.latitude + SAME_COORDINATE_OFFSET * latOffset * 0.85
                      const lng = account.geopoint?.longitude + SAME_COORDINATE_OFFSET * lngOffset

                      return (
                        <Marker
                          key={account.id}
                          position={{ lat, lng }}
                          shouldHide={hiddenResources.has(account.resourceId)
                            || (filterType === 'OLD' && !account.isOldSchool)
                            || (filterType === 'NEW' && account.isOldSchool)
                          }
                          title={account.publicName}
                          // icon={createAvatarIcon({
                          //   account,
                          //   google,
                          // })}
                          label={{
                            text: account.BBID,
                            color: 'white',
                            fontSize: "8px",
                            fontWeight: "bold",
                          }}
                          infoWindow={createInfoWindowContent(account)}
                        />
                      )
                    }
                  )}
                </Clusterer>
              )}
            </MapContext.Consumer>
          </MapComponent>
      </Box>
    </Shell>
  )
}

