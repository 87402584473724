  import * as React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'


import { createCurrencyFormatter } from 'src/utils/currency'
import { UserCountBar } from './UserCountBar'
import { EditSubscriptionPlan } from './EditSubscriptionPlan'


export function SubscriptionPlan({ account, stripeInfo, refreshStripeInfo, users, userLicenseCounts }) {
  const [isEditing, setIsEditing] = React.useState(false)

  const {
    financialUsersLicenseCount,
    productionUsersLicenseCount,
    limitedUsersLicenseCount,
  } = account

  const currencyFormatter = createCurrencyFormatter()

  const { subscription, customer } = stripeInfo

  const unitPrices = [
    subscription.items.data.find(item => item?.price?.nickname?.startsWith('FINANCIAL'))?.price.unit_amount ?? 0,
    subscription.items.data.find(item => item?.price?.nickname?.startsWith('PRODUCTION'))?.price.unit_amount ?? 0,
    subscription.items.data.find(item => item?.price?.nickname?.startsWith('LIMITED'))?.price.unit_amount ?? 0,
  ]

  const totalPrice = subscription?.items.data.reduce((result, item) => (
    result + (item.quantity * item.plan.amount)
  ), 0)


  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>

              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2" color="textSecondary">
                    Account Status
                  </Typography>
                  <Chip label={subscription.status} />
                </Grid>

                <Grid item style={{ flex: 1 }} />

                <Grid item>
                  <Typography variant="h3">
                    {currencyFormatter.format(totalPrice / 100)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {`/ ${account.billingInterval === 'MONTHLY' ? 'month' : 'year'}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <UserCountBar
                isOldSchool={account.isOldSchool}
                accessLevel="FINANCIAL"
                licenseCount={financialUsersLicenseCount}
                userCount={userLicenseCounts.FINANCIAL}
                price={unitPrices[0]}
              />
            </Grid>

            <Grid item xs={12}>
              <UserCountBar
                isOldSchool={account.isOldSchool}
                accessLevel="PRODUCTION"
                licenseCount={productionUsersLicenseCount}
                userCount={userLicenseCounts.PRODUCTION}
                price={unitPrices[1]}
              />
            </Grid>

            <Grid item xs={12}>
              <UserCountBar
                isOldSchool={account.isOldSchool}
                accessLevel="LIMITED"
                licenseCount={limitedUsersLicenseCount}
                userCount={userLicenseCounts.LIMITED}
                price={unitPrices[2]}
              />
            </Grid>

            {Boolean((customer?.balance ?? 0) < 0) && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="textSecondary">
                  Credit Balance
                </Typography>

                <Typography variant="h6">
                  {currencyFormatter.format(Math.abs(customer.balance / 100))}
                </Typography>

                <Typography color="textSecondary">
                  This amount will be applied to your next invoice(s).
                </Typography>
              </Grid>
            )}
          </Grid>

        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            onClick={() => setIsEditing(true)}
          >
            Update Plan
          </Button>

        </CardActions>
      </Card>

      {Boolean(account) && isEditing && (
        <EditSubscriptionPlan
          refreshStripeInfo={refreshStripeInfo}
          account={account}
          stripeInfo={stripeInfo}
          unitPrices={unitPrices}
          onClose={() => setIsEditing(false)}
          userLicenseCounts={userLicenseCounts}
        />
      )}
    </>
  )
}

SubscriptionPlan.propTypes = {
  account: PropTypes.object,
  stripeInfo: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object),
}
